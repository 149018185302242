<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="relative"
    :class="` ${
      isShopPages ? 'shop-wrapper-fixed-search' : 'shop-wrapper-fixed'
    } `"
  >
    <div
      class="vx-navbar-wrapper p-0 md:p-5 block"
      :class="
        classObj +
        `${!isShopPages ? ' fixed ' : 'relative'} ${!sidebar ? ' w-full' : ''}`
      "
    >
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton shop-nav"
        :color="navbarColorLocal"
        :class="{ 'p-0': isShopPages, ...textColor }"
      >
        <div
          class="flex justify-between items-center w-full padder z-40 bg-white"
          style="height: 46px"
          :class="{ 'px-5 pt-4': isShopPages, 'xl:justify-end': !isShopPages }"
        >
          <!-- SM - OPEN SIDEBAR BUTTON -->
          <div class="flex">
            <feather-icon
              class="sm:inline-flex xl:hidden cursor-pointer p-2 pl-0 text-primary2"
              icon="MenuIcon"
              @click.stop="showSidebar"
            />
            <div
              v-if="isShopPages"
              class="md:hidden vx-logo cursor-pointer flex items-center"
              @click="redirectToShopHome"
            >
              <img
                class="mr-2 fill-current text-primary"
                style="width: 28px"
                :src="require('../../../../public/Group-543.svg')"
              />
              <img
                :src="require('../../../../public/Group-544.svg')"
                width="100px"
              />
            </div>
          </div>

          <div
            v-if="isShopPages"
            class="hidden flex-col ease-in duration-300 md:flex mr-4">
            <span>Delivery to</span>
            <ClinicSwap />
          </div>


          <SearchInput v-if="isShopPages" class="hidden md:flex w-full" />

          <!-- <AdminXeroConnection /> -->

          <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

          <!-- <vs-spacer v-if="!isShopPages"/> -->

          <!-- <i18n /> -->

          <!-- <search-bar /> -->
          <div class="flex flex-row justify-items-end">
            <whats-new
              :class="{ 'ml-1': isShopPages }"></whats-new>

            <cart-drop-down />

            <!-- <active-clinic /> -->

            <notification-drop-down />

            <profile-drop-down :profileRouteName="profileRouteName" />
          </div>
        </div>

        <div
          class="flex items-center w-full md:hidden px-5 justify-between bg-white py-2"
          v-if="isShopPages"
        >
          <Navbar v-if="isShopPages" class="md:hidden border-0 pt-3" />
          <SearchInput class="w-full justify-end flex" />
        </div>

        <vs-divider v-if="isShopPages" class="hidden md:block" />
        <Navbar v-if="isShopPages" class="hidden md:block border-0 pt-3" />
        <div
          id="deliver_to"
          class="px-5 py-3 flex md:hidden justify-between w-full items-center gap-2 box-border z-20"
          style="background: #f9f9f9; top: 95px"
          v-if="isShopPages"
        >
          <ClinicSwap title="Deliver to:" class="px-5"/>
        </div>

        <OrgOwnerAlertBanner
          id="alert_banner_mobile"
          class=""
          v-if="isShopPages"
        />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Bookmarks from "./components/Bookmarks.vue";
import I18n from "./components/I18n.vue";
import SearchBar from "./components/SearchBar.vue";
import CartDropDown from "./components/org-owner/ClinicCartDropDown.vue";
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
// import ActiveClinic from "./components/ActiveClinic";
import AdminXeroConnection from "./admin/AdminXeroConnection";
import ClinicSwap from "../../../views/components/shop/clinic.vue";
import SearchInput from "../../../views/components/shop/Search.vue";
import Navbar from "../../../views/components/shop/Navbar.vue";
import OrgOwnerAlertBanner from "../alert-banner/orgOwnerAlertBanner.vue";
import WhatsNew from "@/layouts/components/navbar/components/WhatsNew.vue";

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
    sidebar: {
      type: Boolean,
      default: true,
    },
    profileRouteName: {
      type: String,
      default: "admin-profile",
    },
  },

  components: {
    // ActiveClinic,
    // Bookmarks,
    // I18n,
    // SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown,
    AdminXeroConnection,
    SearchInput,
    ClinicSwap,
    OrgOwnerAlertBanner,
    Navbar,
    WhatsNew
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark")
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
      else return "navbar-default";
    },
    isShopPages() {
      const storeState = this.$store.state
      const routePath = this.$route.path
      if (storeState.AppActiveUser && storeState.AppActiveUser.userRole === 'superAdmin') {
        return routePath.includes("/store/shop") ||
          routePath.includes("/store/product/") ||
          routePath.includes("/store/checkout");
      }
      return routePath.includes("/store");
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    redirectToShopHome() {
      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      const storeState = this.$store.state;

      let routerName = "";
      if (role === "Org Owner") {
        routerName = "OrgOwnerOrderCenter";
      } else {
        if (userType === "doctor") {
          routerName = "DoctorOrderCenter";
        } else {
          routerName = "NurseOrderCenter";
        }
      }
      // superAdmin role checker
      if (
        storeState.AppActiveUser &&
        storeState.AppActiveUser.userRole === "superAdmin"
      ) {
        routerName = "SuperAdminOrderCenter"
      }

      this.$router.push({
        name: routerName,
      });
    },
  }
};
</script>

<style lang="scss" scope>
.padder {
  @media screen and (max-width: 768px) {
    padding: 0.8rem 1rem;
  }
}

.search-wrapper {
  width: 100%;
  max-width: 600px;
}

.navbar-custom {
  &.shop-nav .vs-con-items {
    @apply flex-col;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0px !important;
    padding: 0px !important;
  }
}

.shop-wrapper {
  margin-bottom: -5rem !important;
}
.shop-wrapper-fixed-search {
  margin-bottom: 9rem;
}
.arrow-spacer {
  margin-bottom: 13rem;
}
@media screen and (max-width: 768px) {
  .shop-wrapper-fixed-search {
    margin-bottom: 3rem;
  }
  .arrow-spacer {
    margin-bottom: 6rem;
  }
}

/** Safari */
@media not all and (min-resolution: 0.001dpcm) and (max-width: 768px) {
  .shop-wrapper-fixed-search {
    margin-bottom: 7rem;
  }
  .arrow-spacer {
    margin-bottom: 9rem;
  }
}

/** Chrominum */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) and (max-width: 768px) {
  .shop-wrapper-fixed-search {
    margin-bottom: 7rem;
  }
  .arrow-spacer {
    margin-bottom: 9rem;
  }
}

.top-alert {
  @media (max-width: 768px) {
    top: 133px;
  }
}
</style>
